import(/* webpackMode: "eager" */ "/vercel/path0/components/i18n/lang-context-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FAQ"] */ "/vercel/path0/components/jbda/faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JBDAAboutUs"] */ "/vercel/path0/components/jbda/jbda/jbda-aboutUs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JBDAPromiseBG"] */ "/vercel/path0/components/jbda/jbda/jbda-promise-bg.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/lb/lb-action.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LBHowDoesItWork"] */ "/vercel/path0/components/jbda/lb/lb-how-it-works.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LBPromiseBG"] */ "/vercel/path0/components/jbda/lb/lb-promise-bg.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LBReassuringLogos"] */ "/vercel/path0/components/jbda/lb/lb-reassuring-logos.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LBTestimonies"] */ "/vercel/path0/components/jbda/lb/lb-testimonies.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/newsletter.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/section.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/stats.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/waves.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/markdown.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/reveal.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
