'use client'

import Reveal from '@/components/reveal'
import { Flex, FlexCol } from '@/components/ui/flex'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { I18nDictionary, getLangCode, useI18n } from '../../i18n/lang-context'
import { Author } from '../author'
import { Section } from '../section'
import { SectionTitle } from '../section-title'
import { useAuthorDialog } from './jbda-author'

const I18N: I18nDictionary = {
  title: {
    en: 'Our team',
    fr: 'Notre équipe'
  }
}

export function JBDAAboutUs({ showTitle = true, teamMembers = [] }: { showTitle?: boolean, teamMembers: Author[] }) {

  const i18n = useI18n(I18N)
  const { openAuthorDialog } = useAuthorDialog()

  const searchParams = useSearchParams()
  
  useEffect(() => {
    const slug = searchParams.get('member')?.toLowerCase()
    if(slug) {
      const member = teamMembers.find((author: Author) => author.slug === slug)
      if(member) {
        openAuthorDialog(member)
      }
    }
  }, [])

  return (
    <Section containerClassName='z-2 relative'>
      {showTitle && <SectionTitle title={i18n.title} titleMaxWidth='max-w-md' />}
      <Flex className='justify-center gap-8 lg:gap-16'>
        {teamMembers.filter((member: Author) => member.teamOrder >= 0).map((member: Author, i: number) => (
          <Reveal id='HEAD_SEPARATOR' delay={100 + i * 100} translateX={100} duration={350} key={member.name} className='relative flex w-1/3 flex-col items-center gap-4 sm:w-1/4 lg:w-1/5 lg:gap-8'>
            <span className='relative aspect-square w-full overflow-hidden rounded-full shadow-sm transition-all hover:shadow-md hover:cursor-pointer' onClick={() => openAuthorDialog(member)}>
              <Image src={member.photo.url} alt={member.name} className='transition-all hover:scale-105'
                width={0}
                height={0}
                sizes='250px'
                style={{ objectFit: 'cover' }}
                fill/>
            </span>
            <FlexCol className='items-center gap-2'>
              <div className='whitespace-nowrap text-center text-base font-medium sm:text-lg'>{member.name}</div>
              <div className='text-center text-sm text-primary dark:text-primaryBright'>{member.title[getLangCode()]}</div>
            </FlexCol>
          </Reveal>
        ))}
      </Flex>
    </Section>
  )
}
