'use client'

import Reveal from '@/components/reveal'
import { siteConfig } from '@/config/site.config'
import Image from 'next/image'
import Link from 'next/link'
import { Flex } from '../../ui/flex'


export function LBReassuringLogos() {

  return (
    <Flex className='w-full scale-75 flex-nowrap items-center justify-center gap-8 md:gap-16'>
      <Reveal duration={350} delay={100} translateX={100}>
        <Link href={siteConfig.links.jarvix} target='_blank' rel='noopener noreferrer'>
          <Image src={siteConfig.logo.jarvixVertical} alt={siteConfig.links.jarvix} width={220} height={185} className='saturate-0 transition-all hover:scale-105 hover:saturate-[75%] dark:brightness-150' />
        </Link>
      </Reveal>
      <Reveal duration={350} delay={200} translateX={100}>
        <Link href={siteConfig.links.bda} target='_blank' rel='noopener noreferrer'>
          <Image src={siteConfig.logo.bdVertical} alt={siteConfig.links.bda} width={348} height={148} className='saturate-0 transition-all hover:scale-105 dark:invert' />
        </Link>
      </Reveal>
    </Flex>
  )
}
